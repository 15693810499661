@import '../variables';

.modalTypeUser {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em 5em;

  @media screen and (max-width: $mobile) {
    padding: 2em;
  }
}

.modalTypeUser_btns {
  display: flex;
  gap: 1em;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    align-self: center;
    align-items: center;
  }
}

.modalTypeUser_options {
  display: flex;
  gap: 2em;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}