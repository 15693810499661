.propertyContact {
  display: grid;
  justify-items: center;
  gap: 1em;
  box-shadow: 0px 0px 46px -5px rgba(0,0,0,0.49);
  -webkit-box-shadow: 0px 0px 46px -5px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 0px 46px -5px rgba(0,0,0,0.49);
  padding: 2em;
  position: sticky;
  top: 100px;
}
.propertyContact_btn {
  font-size: 20px;
  gap: 10px !important;

  svg {
    width: 20px !important;
  }
}