.textLocation {
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 15px;
  }

  &.textLocation_svgxl {
    svg {
      width: 25px;
    }
  }
}