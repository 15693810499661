@import '../variables';

.aboutSection {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 4em;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
    border-radius: $radius;;

    @media screen and (min-width: 1400px) {
      max-height: 515px;
    }

    @media screen and (max-width: 1200px) {
      max-height: 600px;
    }
  }

  &.aboutSectionRivert {
    grid-template-columns: 1fr 1.2fr;

    img {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;

      img {
        grid-row: 2/3;
      }
    }
  }
}

.aboutSection_content {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.aboutSection_icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;

    .titleIcon {
      align-items: center;
      text-align: center;
    }
  }
}