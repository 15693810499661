@import './variables';

.btnGeneral {
  text-decoration: none;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .3s ease;
  justify-content: center;

  &.btn_size_xl {
    padding: 0px 20px;
  }
  &.btn_size_lg {
    padding: 0px 15px;
  }
  &.btn_size_md {
    padding: 0px 10px;
  }

  &.btn_primary {
    border: 1px solid $primary;
    background-color: $primary;
  }

  &.btn_secundary {
    border: 1px solid $primary;
    background-color: white;
    color: $primary;
  }

  &.btn_tertiary {
    border: 1px solid #A2A199;
    background-color: #A2A199;
  }

  &.btn_fourth {
    background-color: $blue_green;
    border: 1px solid $blue_green;
  }

  &.btn_transparent {
    background-color: transparent;
    border: none;
    height: unset;
    padding: unset;
  }

  &.rounded {
    border-radius: $radius;;
  }

  &.alignSelfCenter {
    align-self: center;
  }

  &.alignSelfStart {
    align-self: flex-start;
  }

  &.btn_icon {
    justify-content: space-between;
    gap: 1em;

    svg {
      width: 10px;
    }
  }

  &.btn_underline {
    text-decoration: underline;
  }

  &.btn_content_start {
    justify-content: start;
  }

  &.btn_hover_primary:hover {
    border: 1px solid $primary;
    background-color: $primary;
    color: white;
  }

  &.btn_login {
    font-size: 15px;
  }
}