@import '../../variables';

.recentActivityItem {
  display: flex;
  gap: 10px;
  padding-left: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: $primary;
    top: 50%;
    left: 0;
  }

  &:last-child::before {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 50%;
    background-color: $primary;
    top: 0;
    left: 0;
  }

  &:first-child::after {
    display: none;
  }

  img {
    width: 108px;
    object-fit: cover;
    height: 100%;
    border: 2px solid $primary;
    border-radius: $radius;;
  }
}
.recentActivityItem_circle {
  width: 15px;
  height: 15px;
  background: $primary;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);

  &::after {
    content: '';
    position: absolute;
    width: 200%;
    height: 2px;
    background-color: $primary;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}