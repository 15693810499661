@import './variables';

.confirmCode {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .bar_email {
    padding: 10px 0px;
  }
}

.confirmCode_inputs {
  display: flex;
  gap: 15px;

  input {
    width: 50px;
    height: 55px;
    font-size: 37px;
    text-align: center;

    @media screen and (max-width: $mobile) {
      width: 34px;
      height: 39px;
      font-size: 20px;
    }
  }
}