@import '../variables';

.filterModal {
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media screen and (max-width: $mobile) {
    padding: 2em 0 !important;
  }
}

.filterMobile_padding {
  padding: 0 2em;
}

.filterMobile_content {
  overflow: hidden;
  overflow-y: auto;

  @media screen and (max-width: $mobile) {
    max-height: 70vh;
  }
}