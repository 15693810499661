@import '../variables';

.basicInfo {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.basicInfo_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1em;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr 1fr;
  }
}

.basicInfo_list_item {
  display: flex;
  flex-direction: column;
}