@import '../variables';

.markDown { 
  display: flex;
  flex-direction: column;
  gap: 1em;
  text-align: start;
  
  h1 {
    font-size: $xl;
    font-weight: 900;
  }
  
  h2 {
    font-size: $lg;
    font-weight: 600;
  }
  
  h3 {
    font-size: $md;
    font-weight: 600;
  }
  
  h4,
  h5,
  h6 {
    font-size: $sm;
    font-weight: 600;
  }
}