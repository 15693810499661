.featuredPost {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr;
  }

  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
  }
}

.featuredPost_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.8em;
}