@import './variables';

.buttonMoreAndLess {
  display: flex;
  align-items: center;
  gap: 15px;
}

.buttonMoreAndLess_btn {
  border: 1px solid $primary !important;
  width: 20px;
  height: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: $radius;;
}