@import '../variables';

.featuredProperties {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 2rem 0;
}

.featuredProperties_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

.featuredProperties_card {
  box-shadow: 3px 3px 17px -4px rgb(0 0 0 / 40%);
  -webkit-box-shadow: 3px 3px 17px -4px rgb(0 0 0 / 40%);
  -moz-box-shadow: 3px 3px 17px -4px rgb(0 0 0 / 40%);
  border-radius: $radius;
  ;
  position: relative;
  text-decoration: none;
  color: $primary;

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;

    @media screen and (max-width: $laptop_medium) {
      height: 336px;
    }
    @media screen and (max-width: $laptop_small) {
      height: 283px;
    }
    @media screen and (max-width: $tablets) and (min-width: $mobile) {
      height: 450px;
    }
  }
}

.featuredProperties_card_content {
  display: grid;
  gap: 1em;
  padding: 15px 10px;
  grid-template-rows: 95px 1fr;
}

.featuredProperties_card_content_details {
  display: grid;
  align-items: baseline;
}

.featuredProperties_card_save {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  padding: 2px;
  width: 25px;
  height: 25px;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: $radius;
  ;
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;
  }
}