@import '../variables';

.cardPost {
  display: grid;
  flex-direction: column;
  justify-items: start;
  gap: 1.5em;

  p {
    color: $gray;
  }
}
.cardPost_image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
  border-radius: $radius;;
}