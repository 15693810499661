@import './variables';

.inputCustom {
  display: flex;
  gap: 10px;
  flex-direction: column;

  .labelBold {
    font-weight: 600;
    font-size: 20px;
  }

  input,
  select {
    height: 100%;
    background-color: white;
    border: none;
    border-radius: $radius;
    ;
    border: 1px solid #cccccc;
    height: 38px;
    width: 100%;
    color: $gray;

    &.inputIconStart {
      padding-left: 27px;
    }

    &.inputIconEnd {
      padding-right: 27px;
    }
  }
}

.customSelect {
  position: relative;

  &.selectIcon {
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
    }

    select::-ms-expand {
      display: none;
      /*Evita que se muestre la flecha por defecto en versiones de IE*/
    }

    svg {
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      width: 21px;
    }
  }
}

.inputCustom_input {
  position: relative;

  .inputCustom_iconEnd {
    position: absolute;
    right: 3px;
    height: 100%;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
    }
  }
}