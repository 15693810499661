@import '../variables';

.listPost {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
  row-gap: 4em;

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}