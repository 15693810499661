@import '../components/variables';

.propertiesPage {
  display: grid;
  grid-template-columns: 300px 2fr;
  padding: 2em 0;
  gap: 5em;

  @media screen and (max-width: $laptop) {
    grid-template-columns: 1fr;
  }
}
.propertiesPage_filter {
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media screen and (max-width: $laptop) {
    display: none;
  }
}
.propertiesPage_filter_title {
  display: flex;
  gap: 20px;
  svg {
    width: 15px;
  }
}

.propertiesPage_content {
  display: grid;
  gap: 2em;
  justify-items: end;
  align-content: flex-start;

  @media screen and (max-width: $laptop) {
    justify-items: unset;
  }
}
.propertiesPage_content_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  width: 100%;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr;
  }
}

.propertiesPage_pagination {
  grid-column: 1/3;

  @media screen and (max-width: $tablets) {
    grid-column: 1/2;
  }
}

.propertiesPage_title_mobile {
  display: none;

  @media screen and (max-width: $laptop) {
    display: flex;
    gap: 10px;

    svg {
      width: 10px;
    }
  }
}
.propertiesPage_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.propertiesPage_content_header_btn {
  display: flex;
  gap: 1em;
}

.propertiesPage_content_btnFilter_tablet {
  display: none;

  @media screen and (max-width: $laptop_medium) {
    display: flex;
  }
}

.propertiesPage_content_filter {
  svg {
    width: 40px !important;
  }
}

.propertiesPage_content_btnOrder {
  @media screen and (max-width: $mobile) {
    padding: unset !important;
    width: 40px;
    height: 40px;
    justify-content: center !important;
    
    svg {
      width: 20px !important;
    }
  }
}