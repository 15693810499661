@import '../components/variables';

.aboutUs {
  display: flex;
  flex-direction: column;
  gap: 4em;
  margin: 4em 0;
}

.aboutUs_hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 581px;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr;
    height: unset;
  }

  img {
    width: 100%;
    height: inherit;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: $tablets) {
      grid-row: 1/2;
      height: 300px;
    }
  }
}

.aboutUs_hero_content {
  background-color: $primary;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2em;
  padding: 4em;
  height: 100%;

  @media screen and (max-width: $mobile) {
    padding: 2em;
  }
}