@import './variables';

.savePropertie {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  padding: 2px;
  width: 25px;
  height: 25px;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: $radius;;
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;
  }
}