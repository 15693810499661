@import '../components/variables';

.registerPage {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  min-height: 100vh;

  @media screen and (max-width: $laptop_small) {
    grid-template-columns: 1fr;
  }
}

.registerPage_img {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (max-width: $laptop_small) {
    display: none;
  }
}

.registerPage_img_text {
  position: absolute;
  background-color: $primary;
  bottom: 0;
  left: 0;
  padding: 1em;
}

.registerPage_content {
  display: flex;
  flex-direction: column;
}

.registerPage_content_header {
  background-color: $primary;
  display: flex;
  align-items: flex-end;
  padding: 4em 3em 2em;
  justify-content: space-between;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    gap: 2em;
  }
}

.registerPage_content_body {
  display: grid;
  flex-direction: column;
  height: 100%;
  align-content: center;
  gap: 1em;
  padding: 2em;

  @media screen and (max-width: $laptop_small) {
    justify-content: center;
  }
}

.registerPage_content_btnSocial {
  display: flex;
  gap: 1em;

  @media screen and (max-width: $laptop_small) {
    justify-content: center;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    justify-self: center;
  }
}

.registerPage_content_form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}