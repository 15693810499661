@import '../components/variables';

.blogPost {
  display: flex;
  flex-direction: column;
  margin-bottom: 3em;
}

.blogPost_container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr 12fr 1fr;
  }
}

.blogPost_content {
  grid-column: 2/3;
  gap: 2em;
  display: flex;
  flex-direction: column;
  margin-top: 3em;

  img {
    width: 100%;
    border-radius: $radius;;
  }
}

.blogPost_number {
  display: flex;
  gap: 2em;

  @media screen and (max-width: $mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.blogPost_content_images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;

  @media screen and (max-width: $mobile) {
    display: grid;
    grid-template-columns: 1fr;
  }

  img:last-child {
    grid-column: 1/3;

    @media screen and (max-width: $mobile) {
      grid-column: 1/2;
    }
  }
}

.blogPost_share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  padding: 1em 0;
  gap: 1em;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.blogPost_share_social {
  display: flex;
  gap: 1.5em;

  svg {
    width: 40px;
    cursor: pointer;
  }
}