@import '../variables';

.listFeatured {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.listFeatured_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1em;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

.listFeatured_list_item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  svg {
    width: 15px;
  }
}