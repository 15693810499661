@import '../components/variables';

.blog {
  margin-bottom: 4em;
  display: flex;
  flex-direction: column;
  gap: 4em;
}

.blog_content {
  display: flex;
  flex-direction: column;
  gap: 4em;
}