@import './variables';

.propertyGalery {
  display: grid;
  gap: 2em;
  grid-template-columns: 1fr 200px;
  grid-template-rows: 1fr 1fr 1fr;
  height: calc(100vh - 300px);
  min-height: 536px;


  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 145px;
    height: unset;
  }

  @media screen and (max-width: $mobile) {
    gap: 1em;
    grid-template-rows: 1fr 83px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $radius;
    cursor: pointer;
  }
}

.propertyGalery_first {
  grid-column: 1/2;
  grid-row: 1/4;

  @media screen and (max-width: $tablets) {
    grid-column: 1/4;
    grid-row: 1/2;
  }
}

.propertyGalery_last {
  position: relative;
  cursor: pointer;

  @media screen and (max-width: $tablets) {
    height: 100%;
  }
}

.propertyGalery_last_text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #18181873;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 900;
  color: white;
  border-radius: $radius;
}