* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

// helpers
@import './components/helpers';

// pages
@import './pages/home';
@import './pages/register';
@import './pages/properties';
@import './pages/property';
@import './pages/blog';
@import './pages/blogPost';
@import './pages/aboutUs';
@import './pages/dashboardLayout';
@import './pages/dashboardPage';

// components
@import './components/layout';
@import './components/header';
@import './components/buttons';
@import './components/footer';
@import './components/input';
@import './components/heroImg';
@import './components/FeaturedPropertiesList/featuredPropertiesList';
@import './components/allies';
@import './components/placesAndSectors';
@import './components/socialMedia';
@import './components/buttonFacebbok';
@import './components/buttonGoogle';
@import './components/checkBox';
@import './components/Modal/modal';
@import './components/Modal/modalRegister';
@import './components/Modal/modalLogin';
@import './components/confirmCode';
@import './components/properties/inputsFilter';
@import './components/properties/filter';
@import './components/selectCount';
@import './components/buttonMoreAndLess';
@import './components/properties/filterMobile';
@import './components/textLocation';
@import './components/propertyGalery';
@import './components/Title/TitleIcon';
@import './components/Property/basicInfo';
@import './components/Property/listFeatured';
@import './components/Property/propertyContact';
@import './components/Property/placesOfInterest';
@import './components/Property/propertyHeader';
@import './components/Property/sliderGalery';
@import './components/featuredPost';
@import './components/Blog/listPost';
@import './components/Blog/cardPost';
@import './components/Blog/pagination';
@import './components/Blog/blogHero';
@import './components/AboutUs/aboutSection';
@import './components/avatar';
@import './components/Dashboard/navigateDashboard';
@import './components/Dashboard/cardStats';
@import './components/FeaturedPropertiesList/featuredPropertiesDetails';
@import './components/FeaturedPropertiesList/featuredPropertiesPrice';
@import './components/featuredPropertiesLarge';
@import './components/Dashboard/RecentActivity/recentActivity';
@import './components/Dashboard/RecentActivity/recentActivityList';
@import './components/Dashboard/RecentActivity/recentActivityItem';
@import './components/savePropertie';
@import './components/Dashboard/listFavorite';
@import './components/PropertiesInterested/propertiesInterested';
@import './components/PropertiesInterested/propertiesInterestedList';
@import './components/Input/Range.scss';
@import './components/loading/loading';
@import './components/markDown/MarkDown.scss';
@import './components/OrderBy/orderBy';
@import './components/SelectCustom/selectCustom';
@import './components/Maps/maps';
@import './components/AutoComplete/autoComplete';
@import './components/Modal/modalTypeUser';
@import './components/CardCheck/cardCheck';
@import './components/Policy/policy';