@import './variables';

.allies {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em 0;
}

.allies_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

.allies_list_item {
  display: flex;
  flex-direction: column;
  gap: 9px;
  text-decoration: none;
  color: $primary;

  img {
    width: 100%;
    height: 219px;
    object-fit: cover;
  }
}

.allies_list_item_description {
  color: $gray;
  font-size: 14px;
}