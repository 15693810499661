@import '../variables';

.selectCustom__control {
  border-color: #cccccc !important;
  box-shadow: unset !important;

  &:hover, &:focus, &:active, &:target {
    border-color: #cccccc !important;
    box-shadow: unset;
  }
}
.selectCustom__menu {
  .selectCustom__option {
    &:hover, &:focus, &:active, &:target {
      background-color: $primary;
      opacity: .5;
      color: white;
    }
    
    &.selectCustom__option--is-selected {
      background-color: $primary;
    }
  }
}

.selectCustom__indicator {
  svg {
    width: 18px;
  }
}