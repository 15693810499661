@import './variables';

.hero_img {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
  }
}
.hero_img_content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;

  &::before {
    content: '';
    background-color: $gray_dark;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  h1 {
    position: relative;
    text-align: center;
  }
}

.hero_img_select {
  font-size: 13px;
}

.hero_img_content_search_properties {
  display: grid;
  gap: 2em;
  padding: 1em;
  border-radius: 4px;
  margin: 0 15px;
  position: relative;
  grid-template-columns: repeat(4, 180px);

  @media screen and (max-width: $laptop_medium) {
    display: grid;
    grid-template-columns: 1fr;
  }
}
