@import './variables';

.socialMedia {
  display: flex;
  gap: 2em;
  padding-top: 2em;
  height: 21em;

  @media screen and (max-width: $mobile) {
    height: unset;
    flex-direction: column;
  }
}

.socialMedia_left {
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media screen and (max-width: $mobile) {
    text-align: center;
  }
}

.socialMedia_right {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    position: absolute;
    bottom: 0px;
    max-width: 526px;
    width: 100%;
    min-width: 20em;

    @media screen and (max-width: $mobile) {
      position: relative;
    }
  }
}

.socialMedia_list {
  display: flex;
  gap: 1em;
}

.socialMedia_list_item {
  display: flex;
  background-color: white;
  border-radius: $radius;;

  a {
    display: flex;
  }

  svg {
    width: 5em;

    @media screen and (max-width: $tablets) {
      width: 50px;
    }
    
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }
}

.linkShare {
  display: flex;
  align-items: center;
  cursor: pointer;
}