@import '../variables';

.navigateDashboard {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.navigateDashboard_item,
.navigateDashboard_active {
  text-decoration: none;
  color: $gray;
  border: 1px solid $gray;
  text-align: center;
  padding: 14px;
  border-radius: $radius;;
}

.navigateDashboard_active {
  background-color: $primary;
  border-color: $primary;
  color: white;
}

.navigateDashboardMobile_item {
}
.navigateDashboardMobile_item__control {
  border-color: $gray !important;
  box-shadow: unset !important;

  &:hover, &:focus, &:active, &:target {
    border-color: unset !important;
    box-shadow: unset;
  }
}
.navigateDashboardMobile_item__option {
  &:hover {
    background-color: $primary;
    opacity: .5;
    color: white;
  }
}
.navigateDashboardMobile_item__option--is-selected {
  background-color: $primary !important;
}