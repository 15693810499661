@import '../variables';

.loadingContainer_2 {
  grid-template-columns: 1fr 1fr;
}

.loadingContainer_3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.loadingContainer_2,
.loadingContainer_3 {
  margin: 2em 0;
  display: grid;
  gap: 2em;

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}