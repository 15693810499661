@import './variables';

.avatar {
  width: 40px;
  height: 40px;
  border-radius: $radius;;
  background-color: $primary;
  cursor: pointer;
  padding: 3px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $radius;;
    object-position: center;
  }
}