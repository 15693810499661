@import '../variables';

.featuredPropertiesPrice {
  font-weight: bold;
  display: flex;

  span {
    background-color: $grey;
    padding: 5px 15px;
    border-radius: $radius;
    ;
    font-weight: 600;
  }
}