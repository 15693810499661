@import './variables';

.buttonFacebook {
  border: 1px solid $facebook;
  color: $facebook;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 15px;
  cursor: pointer;

  svg {
    width: 30px;
  }
}