.autoComplete {
  position: relative;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 1;
  box-shadow: 3px 3px 17px -4px rgb(0 0 0 / 40%);
  -webkit-box-shadow: 3px 3px 17px -4px rgb(0 0 0 / 40%);
  -moz-box-shadow: 3px 3px 17px -4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  background: white;
  width: 100%;
}

.suggestion-item,
.suggestion-item--active {
  cursor: pointer;
  padding: 7px;
}

.suggestion-item--active {
  background-color: #fafafa
}