@import '../variables';

.orderBy_select {
  width: 212px;

  @media screen and (max-width: $laptop) {
    width: 40px;
    
    .selectCustom__value-container {
      display: none;
    }
    .selectCustom__menu {
      width: 212px;
      right: 0;
    }
  }
}