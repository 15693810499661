@import '../variables';

@keyframes modal {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.modal{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.733);
  z-index: 99;
  display: grid;
  justify-content: center;
  align-items: center;
  animation: modal .1s ease forwards;
  backdrop-filter: blur(5px);

  &.modal_direction_right {
    justify-content: start;
    align-items: unset;

    @media screen and (max-width: $mobile) {
      justify-content: center;
      align-items: center;
    }
  }
}

@keyframes modal_content {
  0%{
    top: -200px;
    opacity: 0;
  }
  20%{
    top: 20px;
  }
  40%{
    top: -20px;
  }
  60%{
    top: 10px;
  }
  80%{
    top: -10px;
  }
  100%{
    top: 0;
    opacity: 1;
  }
}
.modal_content{
  background-color: white;
  border-radius: $radius;;
  max-width: 90vw;
  max-height: 90vh;
  position: relative;
  top: -200px;
  opacity: 0;
  animation: modal_content .2s ease .1s forwards;
  z-index: 3;

  &.modal_content_max_height {
    max-height: 100vh;
    max-width: unset;
    width: 60vw;
    padding: 2em;

    @media screen and (max-width: $mobile) {
      max-width: 90vw;
      max-height: 90vh;
      width: 100%;
      padding: 0;
    }
  }

  &.Modal-sm{
    min-width: 320px;
    min-height: 210px;
  }
}

.modal_close{
  position: absolute;
  top: 4px;
  right: 10px;
  font-weight: bold;
  font-size: 16px;
  color: $gray;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.modal_window{
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  z-index: 2;

  &.fixed{
    position: fixed;
    top: 0;
  }
}

.modal-md {
  padding: 2em 6em;

  @media screen and (max-width: $mobile) {
    padding: 2em;
  }
}

.modal_header {
  padding: 1em 0;
  background: $primary;
  text-align: center;
  color: white;
}