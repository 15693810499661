.featuredPropertiesDetails {
  display: flex;
  gap: 15px;
  justify-content: center;
  color: $gray;
}
.featuredPropertiesDetails_item {
  display: flex;
  gap: 5px;
  align-items: center;

  svg {
    width: 15px;
  }
}