@import '../variables';

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $mobile) {
    justify-content: center;
  }
}

.pagination_pages {
  display: flex;
  align-items: center;
  gap: 1em;
}

.pagination_btn {
  width: 80px; 

  @media screen and (max-width: $mobile) {
    display: none;
  }
}