.propertiesInterested {
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 40px;
    border-radius: 100%;
    height: 40px;
    object-fit: cover;
  }
}