@import './variables';

.footer {
  display: grid;
  grid-auto-flow: column;
  height: 22em;
  justify-content: space-between;
  align-content: center;

  @media screen and (max-width: $tablets) {
    grid-auto-flow: row;
    height: unset;
    padding: 4em 0;
    gap: 3em;
    justify-content: center;
  }  
}

.footer_navigate {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (max-width: $tablets) {
    text-align: center;
  }  

  a {
    color: white;
    letter-spacing: 2px;
  }
}
.copyRight{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1em 0;

  @media screen and (max-width: $mobile) {
    height: unset;
    padding: 10px;
  }

  a{
    color: gray;
  }
}