@import '../variables';

.filterProperties {
  padding: 1.5em 0;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media screen and (max-width: $laptop_medium) {
    border: none;
  }
}

.filterProperties_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    width: 12px;
  }
}

.filterProperties_content {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.filterProperties_titleItem_arrow {
  display: flex;
  gap: 10px;
    
  svg {
    width: 15px;
  }
}

.filterProperties_checkBoxs {
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 1fr;
}

.filterProperties_checkLabel {
  font-size: 20px;
}

.filterProperties_total {
  background-color: $blue_green;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: $radius;;
}
