@import '../components/variables';

.property {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 2em 0;
}
.property_description {
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.property_info {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 2em;
  position: relative;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr;
  }
}
.property_item {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.property_video{
  iframe {
    width: 100%;
    height: 400px;
  }
}

.property_map {
  width: 100%;
  height: 400px;
}