@import '../variables';

.modalSlider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a6;
  z-index: 9;
  display: grid;
  align-items: center;

  img {
    background-color: white;
    padding: 5px;
    border-radius: $radius;
    width: auto;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: $laptop_medium) {
      width: 100%;
    }
  }
}

.modalSliderGalery_close {
  position: absolute;
  background-color: white;
  top: 2%;
  right: 4%;
  width: 30px;
  height: 30px;
  border-radius: $radius;
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.modalSliderGalery_imgPrimary {
  max-width: 972px;
  height: 70vh;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
  justify-self: center;

  @media screen and (max-width: $laptop_medium) {
    max-width: 779px;
    height: 56vh;
  }

  @media screen and (max-width: $tablets) {
    height: 46vh;
  }

  @media screen and (max-width: $mobile) {
    height: 33vh;
  }
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: $radius;
  ;
  width: 40px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 40px;
  z-index: 2;

  svg {
    width: 15px;
  }
}

.next {
  right: -90px;

  @media screen and (max-width: $laptop_medium) {
    right: -40px;
  }

  @media screen and (max-width: $tablets) {
    right: 40%;
    bottom: -70px;
    top: unset;
    transform: translateX(100%);
  }
}

.prev {
  left: -90px;

  @media screen and (max-width: $laptop_medium) {
    left: -40px;
  }

  @media screen and (max-width: $tablets) {
    left: 40%;
    bottom: -70px;
    top: unset;
    transform: translateX(-100%);
  }
}

.swiper {
  width: 100%;
  height: 23vh;
  max-width: 1149px;
  margin: 0 2em;
  display: flex;
  justify-self: center;
  padding: 0 1em;

  @media screen and (max-width: $tablets) {
    height: 19vh;
  }

  @media screen and (max-width: $mobile) {
    height: 14vh;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: $radius;
  ;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $radius;
  ;
}

.swiper-pagination {
  display: none;
}

.sliderActive {
  padding: 5px;
}