@import './variables';

@font-face{
  font-family: 'Poppins';
  src: url(../../font/Poppins/Poppins-Light.ttf);
  font-weight: 300;
}
@font-face{
  font-family: 'Poppins';
  src: url(../../font/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
}
@font-face{
  font-family: 'Poppins';
  src: url(../../font/Poppins/Poppins-SemiBold.ttf);
  font-weight: 600;
}
@font-face{
  font-family: 'Poppins';
  src: url(../../font/Poppins/Poppins-Black.ttf);
  font-weight: 700;
}
@font-face{
  font-family: 'Poppins';
  src: url(../../font/Poppins/Poppins-Bold.ttf);
  font-weight: 900;
}

html {
  font-family: 'Poppins';
  font-size: 16px;
}
button {
  font-family: 'Poppins';
}

.container {
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
  width: 100%;

  .content {
    grid-column: 2/3;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

h1 {
  font-size: $xxl;
  font-weight: 900;

  @media screen and (max-width: $mobile) {
    font-size: $xl;
  }
}

h2 {
  font-size: $xl;
  font-weight: 600;

  @media screen and (max-width: $mobile) {
    font-size: $lg;
  }
}

h3 {
  font-size: $lg;
  font-weight: 600;

  @media screen and (max-width: $mobile) {
    font-size: $md;
  }
}

h4,
h5,
h6 {
  font-size: $md;
  font-weight: 600;
}

input, select {
  padding: 0px 10px;

  &:focus {
    outline: none;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}