@import '../variables';

.cardCheck {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid $primary;
  border-radius: 4px;
  padding: 1em;
  width: 227px;
  cursor: pointer;
}
.cardCheck_active {
  display: flex;
  justify-content: flex-end;
}
.cardCheck_active_item {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid $primary;
  position: relative;

  &.cardCheck_select::before {
    content: '';
    background-color: $primary;
    width: 60%;
    height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}
.cardCheck_icon {
  background-color: $primary;
  padding: 1em;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin: 0 auto;
}