@import '../components/variables';

.dashboardLayout {
  padding: 2em 0;
  display: grid;
  grid-template-columns: 238px 1fr;
  gap: 2em;

  @media screen and (max-width: $laptop_medium) {
    grid-template-columns: 1fr;
  }
}
.dashboardLayout_navigate {

}