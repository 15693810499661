@import '../variables';

.blogHero {
  width: 100%;
  height: 280px;
  position: relative;

  @media screen and (max-width: $mobile) {
    height: 380px;
  }

  &::before {
    content: '';
    background-color: $gray_dark;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
}

.blogHero_container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  @media screen and (max-width: $mobile) {
    text-align: center;
  }
}

.blogHero_text {
  max-width: 600px;
  width: 100%;
}

.blogHero_title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    white-space: nowrap;
  }

  @media screen and (max-width: $tablets) {
    display: grid;
  }
}

.blogHero_date {
  @media screen and (max-width: $tablets) {
    grid-row: 1/2;
  }
}

.blogHero_content {
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 10px;
}

.blogHero_goBack {
  display: grid;
  justify-content: center;
  padding-top: 11px;

  &.blogHero_goBack_mobile {
    @media screen and (max-width: $tablets) {
      padding: unset;
    }
  }

  svg {
    width: 15px;
  }
}