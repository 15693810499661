@import '../variables';

.propertyHeader {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.propertyHeader_name {
  display: flex;
  justify-content: space-between;
  gap: 1em;

  @media screen and (max-width: $mobile) {
    display: grid;
  }
}

.propertyHeader_code {
  font-weight: normal;
  color: $gray;
  margin-left: 10px;
}

.propertyHeader_share {
  display: flex;
  align-items: center;
  gap: 1em;

  @media screen and (max-width: $mobile) {
    grid-row: 1/2;

    span {
      display: none;
    }
  }

  svg {
    width: 40px;
  }
}