@import '../variables';

.input_range_label {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-weight: 600;
  font-size: 20px;
}

.input_range {
  width: 100%;
  position: relative;
  height: 42px;
}

.input_range_slider {
  position: relative;
  width: 100%;
}

.input_range_slider__track,
.input_range_slider__range,
.input_range_slider__left-value,
.input_range_slider__right-value {
  position: absolute;
}

.input_range_slider__track,
.input_range_slider__range {
  border-radius: 3px;
  height: 5px;
}

.input_range_slider__track {
  background-color: $grey;
  width: 100%;
  z-index: 1;
}

.input_range_slider__range {
  background-color: $primary;
  z-index: 2;
}

.input_range_slider__left-value,
.input_range_slider__right-value {
  color: $gray;
  font-size: 12px;
  margin-top: 20px;
}

.input_range_slider__left-value {
  left: 6px;
}

.input_range_slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.input_range_thumb,
.input_range_thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.input_range_thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.input_range_thumb--left {
  z-index: 3;
}

.input_range_thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.input_range_thumb::-webkit-slider-thumb {
  background-color: $primary;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px $grey;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.input_range_thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px $grey;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
