.titleIcon {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  svg {
    width: 30px;
  }

  &.sizeIcon_xl svg {
    width: 40px;
  }

  &.titleIconTop {
    flex-direction: column;
    align-items: self-start;
  }
}