@import './variables';

.propertiesLarge {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2em;
}

.featuredPropertiesLarge {
  text-decoration: none;
  display: grid;
  grid-template-columns: 300px 1fr;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }

  img {
    width: 100%;
    max-height: 166px;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: $mobile) {
      max-height: 300px;
    }
  }
}

.featuredPropertiesLarge_shadow {
  box-shadow: 1px 1px 13px rgb(165 165 165 / 52%);
}

.featuredPropertiesLarge_image {
  position: relative;
  display: flex;
}

.featuredPropertiesLarge_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  align-items: flex-start;

  @media screen and (max-width: $mobile) {
    align-items: inherit;
    gap: 10px;
  }
}